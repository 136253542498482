import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="box">
    <img src={logo} id="logo" alt="logo"/> 
    <div id="title">
      SITE EM MANUTENÇÃO
    </div>
    <div id="descricao">
     Estamos trabalhando muito para oferecer a você a melhor experiência!
     <br/>
     <p>Volte mais tarde.</p>
    </div>
  </div>
  );
}

export default App;
